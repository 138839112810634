/* jon, 1/27/22: Add padding below last row so row hover buttons have to room to grow into - but only on grids with a row action bar */
.k-grid.grid-with-actionbar > div.k-grid-container > div.k-grid-content {
  padding-bottom: 51px;
}

.k-grid.node-schedule-grid {
  box-shadow: none;
  border-top-left-radius: 0;
  border: 1px solid var(--carbon-lightergray);
  border-left-width: 0;
}

.k-carbon-grid {
  box-shadow: 2px 2px 8px var(--carbon-griddropshadow);
  border-radius: 6px;
  min-height: 300px;

  /* Grid row expansion icon */
  .k-i-add:before,
  .k-i-plus:before,
  .k-plus:before {
    content: "\E014";
  }

  /* Grid row shrink icon */
  .k-i-kpi-trend-equal:before,
  .k-i-minus:before,
  .k-minus:before {
    content: "\E015";
  }

  /* Remove right border in header since it interferes with column selector button */
  div.k-grid-header-wrap {
    border-width: 0;
    border-radius: 6px;
  }

  /* Make sure radius shows up for all four corners */
  div.k-grid-container {
    border-radius: 6px;
  }

  /* Turn off default hover state on header row*/
  .k-grid table tr:hover,
  .k-grid table tr.k-state-hover,
  .k-grid table td.k-state-focused,
  .k-grid table th.k-state-focused,
  .k-grid table th:focus,
  .k-grid table .k-master-row > td:focus,
  .k-grid table .k-grouping-row > td:focus,
  .k-grid table .k-detail-row > td:focus,
  .k-grid table .k-group-footer > td:focus {
    background-color: transparent;
  }

  /* ensure all grid tables are position relative for action button positioning */
  .k-grid-table {
    position: relative;
  }

  table {
    table-layout: fixed;

    colgroup {
      col.k-sorted {
        background-color: var(--carbon-white);
      }
    }

    thead {
      tr {
        height: 26px !important;

        &.k-alt {
          background-color: var(--carbon-verylightgray);
        }

        th {
          color: var(--carbon-darkgray);
          font-size: 14px;
          font-weight: 600;
          padding: 2px 24px;

          &.k-header {
            background-color: var(--carbon-white);

            &:hover {
              color: var(--carbon-white);
              background-color: var(--carbon-blue);

              .k-link {
                color: var(--carbon-white);
              }

              input.k-checkbox {
                color: var(--carbon-blue);
                background-color: var(--carbon-white);
                border-color: var(--carbon-white);
              }
            }
          }

          &.k-sorted {
            background-color: var(--carbon-white);

            .k-i-sort-asc-sm,
            .k-i-sort-desc-sm,
            .k-sort-order {
              color: var(--carbon-blue);
            }

            &:hover .k-sort-order {
              color: var(--carbon-white);
            }
          }

          &:first-child {
            border-left: 5px solid transparent;
          }
        }

        th[role="columnheader"] {
          border-bottom: 1px solid var(--carbon-darkgray);
        }
      }

      tr.k-filter-row th {
        background-color: var(--carbon-lightergray);
        padding: 0 8px 0 24px;

        input.k-textbox,
        .k-input,
        .k-select,
        .k-button {
          padding: 0;
          font-size: 14px;
          font-weight: normal;
          height: 24px !important;
        }

        &.k-dropdown-wrap {
          margin-bottom: 5px;
        }

        div.k-filtercell button,
        div.k-filtercell span.k-select {
          width: 22px;
        }
      }
    }

    tbody {
      tr {
        height: 51px;
        cursor: pointer;
        position: relative;

        &.grid-insert-row-overlay {
          opacity: 0.5;
          background-color: var(--carbon-verylightgray) !important;
        }

        &:active,
        &:hover {
          background-color: var(--carbon-verylightgray);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-mediumgray);
            }
          }
        }

        &.k-alt {
          background-color: var(--carbon-verylightgray);
        }

        &.k-grid-edit-row,
        &.k-state-selected,
        &.k-detail-row {
          background-color: var(--carbon-lightblue);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-blue);
            }
          }
        }

        &.k-grid-edit-row.carbon-insert-row,
        &.k-state-selected.carbon-insert-row {
          background-color: var(--carbon-lightorange);

          td {
            &:first-child {
              border-left: 5px solid var(--carbon-orange);
            }
          }
        }

        &.k-grid-edit-row.carbon-delete-row,
        &.k-state-selected.carbon-delete-row {
          background-color: var(--carbon-lightred);

          td {
            &:first-child {
              border-left: 5px solid var(--carbon-red);
            }
          }
        }

        &.k-grid-edit-row {
          > td {
            &.k-grid-edit-cell {
              > .validation-msg {
                background-color: var(--carbon-orange);
                padding: 2px 8px;
                color: var(--carbon-white);
                border-radius: 2px;
                position: absolute;
                top: 0;
                left: 8px;
                z-index: 99;
                font-size: 14px;

                > i {
                  vertical-align: middle;
                  padding-right: 10px;
                }
              }
            }

            &.override-cell input {
              font-weight: bold;
            }

            &.validation-error-cell > input,
            &.validation-error-cell > span.k-datepicker {
              border: 2px solid var(--carbon-orange) !important;
            }

            > .k-textbox,
            .k-input[role="combobox"],
            .k-numerictextbox,
            .k-dropdown {
              font-size: 16px;
              background-color: var(--carbon-white);

              &:focus {
                border-bottom-color: var(--carbon-orange);
              }
            }

            > .k-numerictextbox {
              margin-left: -10px;
            }

            input.k-input[role="combobox"] {
              margin-left: 4px;
            }

            > .k-combobox,
            .k-dropdown {
              &.k-widget {
                &:not(.k-switch) {
                  background-color: var(--carbon-white);
                  margin-left: -20px;
                }
              }
            }

            div.k-datetimepicker,
            span.k-datepicker,
            div.k-timepicker {
              background-color: var(--carbon-white);
              margin-left: -15px;

              > div.k-picker-wrap,
              span.k-picker-wrap {
                border-width: 0;
                padding-left: 0;

                &:focus {
                  border-bottom-color: var(--carbon-orange);
                }

                input {
                  font-size: 16px;
                }
              }
            }
          }
        }

        &.k-grid-norecords td {
          text-align: left;
        }

        td.carbon-gridcell-text {
          text-overflow: ellipsis;
          white-space: pre;
        }

        td {
          color: var(--carbon-black);
          white-space: nowrap;
          text-overflow: clip;
          font-size: 16px;
          font-weight: 400;
          padding: 8px 24px;

          &:first-child {
            border-left: 5px solid transparent;
          }

          &.override-cell {
            font-weight: bold;

            span[class^="material-icons"] {
              font-size: 16px;
              color: var(--carbon-blue);
              display: inline-flex;
              vertical-align: middle;
              margin-left: 5px;
              margin-top: -2px;
              padding: 2px;

              &:hover {
                color: var(--carbon-black);
              }
            }
          }
        }
      }
    }
  }

  &.compact-grid {
    line-height: 1.5;
    font-size: 12px;

    // additional styles here ...
  }
}

// Subgrid classes
div.k-grid tr.k-detail-row {
  tr.k-alt {
    background-color: var(--carbon-white);

    &.k-state-selected {
      background-color: var(--carbon-lightblue);
    }
  }

  td.k-detail-cell {
    div.k-carbon-grid {
      min-height: 50px;

      tbody tr {
        &:active,
        &:hover {
          background-color: var(--carbon-verylightgray);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-mediumgray);
            }
          }
        }

        &.k-grid-edit-row,
        &.k-state-selected {
          background-color: var(--carbon-lightblue);

          > td {
            &:first-child {
              border-left: 5px solid var(--carbon-blue);
            }
          }
        }

        td {
          &:first-child {
            border-left: 5px solid transparent;
          }
        }
      }
    }
  }
}
