ul.k-menu {
  height: 100%;
}

// appbarsection creates a parent div to host it's children
.carbon-main-menu ul.k-menu {
  height: 100%;

  li.k-menu-item:hover {
    background-color: var(--carbon-orange) !important;
  }

  li.k-menu-item {
    transition: all 0.5s !important;
    cursor: pointer;
  }
  
  > .k-menu-item + .k-menu-item {
    margin-left: 0;
  }
}

div.action-bar-extras-menu li:hover {
  cursor: pointer;
}

div.k-menu-popup > ul > li:hover {
  cursor: pointer;
  color: var(--carbon-white) !important;
  background-color: var(--carbon-blue) !important;
  .create-node-group {
    cursor: pointer;
    color: var(--carbon-white) !important;
    background-color: var(--carbon-blue) !important;
  }
}

.create-node-group:hover {
  cursor: pointer;
  color: var(--carbon-white) !important;
  background-color: var(--carbon-blue) !important;
}

.daygroup-menu-item {
  :hover {
    color: var(--carbon-white);
  }
}

li.day-group-menu-item-root:hover {
  background-color: transparent !important;
}

// .k-menu-popup .k-menu-group .day-group-menu-item {
//   border-radius: 10px;
// }

// .k-animation-container .k-menu-popup .k-menu-group .day-group-menu-item {
//   @at-root .k-animation-container {
//     border-radius: 10px;
//   }
// }

// .k-animation-container {
//   & > .k-menu-popup {
//     & .day-group-menu-item {
//       border-radius: 10px;
//     }
//   }
// }
