div.k-dialog-wrapper {
  div.k-window-title > h1 {
    width: 100%;
    text-align: center;
    color: var(--carbon-orange);

    span[class^="material-icons"] {
      font-size: 36px;
    }
  }

  div.k-window-content p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  div.k-dialog-buttongroup {
    border-width: 0;
    justify-content: center;
    margin-bottom: 10px;

    button.k-button:not(.k-primary) {
      background-color: var(--carbon-white);
      margin-right: 40px;
    }
  }
}

.dayparts-select-dialog .k-dialog-titlebar {
  padding: 0;
  border-bottom-width: 1px;
}
