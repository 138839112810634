:root {
  --carbon-black: #{$carbon-black};
  --carbon-lightorange: #{$carbon-lightorange};
  --carbon-orange: #{$carbon-orange};
  --carbon-darkOrange: #{$carbon-darkOrange};
  --carbon-lightblue: #{$carbon-lightBlue};
  --carbon-blue: #{$carbon-blue};
  --carbon-darkblue: #{$carbon-darkBlue};
  --carbon-verylightgray: #{$carbon-veryLightGray};
  --carbon-lightergray: #{$carbon-lighterGray};
  --carbon-lightgray: #{$carbon-lightGray};
  --carbon-mediumgray: #{$carbon-mediumGray};
  --carbon-darkgray: #{$carbon-darkGray};
  --carbon-veryDarkGray: #{$carbon-veryDarkGray};
  --carbon-white: #{$carbon-white};
  --carbon-griddropshadow: #{$carbon-gridDropShadow};
  --carbon-primarybuttonhover: #{$carbon-primaryButtonHover};
  --carbon-green: #{$carbon-green};
  --carbon-yellow: #{$carbon-yellow};
  --carbon-yellowgreen: #{$carbon-yellowgreen};
  --carbon-lightred: #{$carbon-lightred};
  --carbon-red: #{$carbon-red};
  --carbon-darkred: #{$carbon-darkred};
}

* {
  box-sizing: border-box;
}

::selection {
  color: $white;
  text-shadow: none;
  background-color: $carbon-orange;
}

html,
body,
#root,
.App {
  height: 100%;
}

html {
  background-color: $white;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  color: $black;
  background: $white;
  font-family: $body-font;
  font-size: 1rem;
  line-height: 1;
}

a {
  color: $brand;
  text-decoration: none;
  transition: color 0.5s ease-in-out, border-color 0.5s ease-in-out;

  &:not(.button) {
    font-weight: 600;
  }

  &:hover,
  &:focus {
    color: darken($brand, 10%);
  }

  &:active {
    color: darken($brand, 15%);
  }
}

p {
  margin: 0 0 2rem;
  word-wrap: break-word; // if a word is longer than an entire line, break it to the next line (often true with email addresses on phones) - otherwise won't affect anything

  &:last-child {
    margin-bottom: 0;
  }

  small {
    font-size: 80%;
  }
}

b,
strong {
  color: $black;
  font-weight: 600;

  p & {
    font-weight: 600;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $gray;
  margin: 1em auto;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

address {
  font: inherit;
  padding: 0;
}

iframe {
  width: 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $header-font;
  font-weight: 600;
  font-style: normal;
  line-height: 1.2;

  b,
  strong {
    font-weight: 700 !important;
  }

  a {
    border: 0 !important;
  }
}

h1 {
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  color: var(--carbon-black);
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

/*Remove Chrome autofill colors on inputs*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--carbon-white) inset !important;
}

/*Remove up/down arrows on number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

span.k-numerictextbox span.k-select {
  display: none;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}

/* important is used here because of a kendo zindex bug where popups are not appearing*/
.k-dialog-wrapper {
  z-index: 100 !important;
}

label.k-label {
  font-family: $body-font;
}

.flip-vertical {
  transform: rotateX(180deg);
}
