.k-notification-group {
  z-index: 11000;
  padding: 0 40px 40px 0;

  .fade-in {
    opacity: 1;
    transition: opacity 500ms ease;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 1s ease 3s;
  }

  div.k-notification-container {
    max-width: 600px;

    div.k-notification-content span {
      word-break: break-all;
    }
  }
}

.file-upload-warning.k-animation-container {
  width: 100%;
  div div.k-notification-container {
    width: 100%;
  }
}
