span.k-datepicker.previewer-date-picker {
  background-color: var(--carbon-white);
  width: 120px;
  background: var(--carbon-white);
  border: 1px solid var(--carbon-lightgray);
  box-sizing: border-box;
  border-radius: 6px;

  > div.k-picker-wrap,
  span.k-picker-wrap {
    border-width: 0;
    padding-left: 0;
    padding-right: 3px;

    input {
      font-size: 16px;
      margin-left: 2px;
      padding-left: 4px;
    }
  }
}
