.previewer-wrapper {
  position: relative;
  margin: 10px 0;
  height: 250px;
  max-height: 250px;
  background: var(--carbon-darkgray);
  box-shadow: 2px 2px 8px var(--carbon-griddropshadow);
  border-radius: 6px;
  overflow: hidden;

  // #carbon-previewer video {
  //   max-width: unset;
  // }
}

#carbon-previewer video {
  max-width: unset;
}

.previewer-spinner {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
