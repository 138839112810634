@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin loader($color: #ffffff) {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $color;
  border-radius: $radius-rounded;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@mixin center($width, $height: 0) {
  position: absolute;
  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2));
  } @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 1.5));
  }
}
