div.k-tabstrip {
  .k-content {
    background-color: var(--carbon-white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-left: 1px solid var(--carbon-lightergray);
    border-right: 1px solid var(--carbon-lightergray);
    border-bottom: 1px solid var(--carbon-lightergray);
    border-top: 1px solid var(--carbon-lightergray);
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    height: 600px;
    display: none !important;

    .k-animation-container {
      width: 100%;
    }
  }

  .k-tabstrip-items {
    border: 0;

    :hover {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    .k-state-active {
      border-left: 1px solid var(--carbon-lightergray);
      border-right: 1px solid var(--carbon-lightergray);
      border-top: 1px solid var(--carbon-lightergray);
      border-bottom: 0;

      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      background-color: var(--carbon-white);
      font-weight: bold;
    }

    .k-state-active::after {
      border-bottom-width: 0;
    }

    .k-link {
      text-transform: none;
      color: var(--carbon-blue);
      letter-spacing: 0.5px;
      line-height: 20px;
      font-size: 16px;
    }

    .k-state-active .k-link {
      color: var(--carbon-black);
    }
  }
}
