// Kendo Material Theme Variables
// https://www.telerik.com/kendo-react-ui/components/styling/theme-material/customization/#toc-common

// Util
// ===========================================================
/// Border radius for all components.
$border-radius: 6px;
/// The background of the components' chrome area.
//$base-bg: #ffffff;
/// The text color of the components' chrome area.
//$base-text: map-get($theme, component-text)	map-get($theme, component-text);
/// The border of the components' chrome area.
//$base-border: rgba( if($theme-type == dark, white, black), .08 );
/// The gradient background of the components' chrome area.
//$base-gradient: null;
/// The background of hovered items.
//$hovered-bg: map-get( $theme, hover-bg );
/// The text color of hovered items.
//$hovered-text: $base-text;
/// The border color of hovered items.
//$hovered-border: rgba( if($theme-type == dark, white, black), .15 );
/// The gradient background of hovered items.
//$hovered-gradient: null;
/// The background of selected items.
//$selected-bg: $secondary;
/// The text color of selected items.
//$selected-text:	$secondary-contrast;
/// The border color of selected items.
//$selected-border: rgba( if($theme-type == dark, white, black), .1 );
/// The gradient background of selected items.
//$selected-gradient:	null;

// Typography
// ===========================================================
/// Base font size across all components.
//$font-size: 14px;
/// Font family across all components.
$font-family: $body-font, $family-sans-serif;
/// Font family for monospaced text. Used for styling the code.
//$font-family-monospace: Consolas, "Ubuntu Mono", "Lucida Console", "Courier New", monospace;
/// Line height used along with $font-size.
//$line-height: (28 / $font-size)

// Button
// ===========================================================
/// Width of border around buttons
//$button-border-width: 0px;
/// Border radius of buttons
//$button-border-radius: $border-radius;
/// Horizontal padding of buttons
//$button-padding-x: $padding-x;
/// Vertical padding of buttons
//$button-padding-y: 8px;
//$button-font-family: $font-family;
//$button-font-size: $font-size;
//$button-line-height: (20 / 14);
//$button-padding-x-sm: $button-padding-x / 2;
//$button-padding-y-sm: $button-padding-y / 2;
//$button-line-height-sm: (20 / 14);
//$button-calc-size: calc( #{$button-line-height * 1em} + #{$button-padding-y * 2} + #{$button-border-width * 2} );
//$button-inner-calc-size: calc( #{$button-line-height * 1em} + #{$button-padding-y * 2} );
/// The background of the buttons.
//$button-bg: $base-bg;
/// The text color of the buttons.
//$button-text: $base-text;
/// The border color of the buttons.
//$button-border: $button-bg;
/// The background gradient of the buttons.
//$button-gradient: null;
//$button-shadow: $box-shadow-depth-2;
/// The background of hovered buttons.
//$button-hovered-bg: null;
/// The text color of hovered buttons.
//$button-hovered-text: null;
/// The border color of hovered buttons.
//$button-hovered-border: null;
/// The background gradient of hovered buttons.
//$button-hovered-gradient: null;
//$button-hovered-shadow: null;
/// The background color of active buttons.
//$button-active-bg: null;
/// The text color of active buttons.
//$button-active-text: null;
/// The border color of active buttons.
//$button-active-border: null;
/// The background gradient of active buttons.
//$button-active-gradient: null;
//$button-active-shadow: $box-shadow-depth-3;
/// The background color of selected buttons.
//$button-selected-bg: $button-active-bg;
/// The text color of selected buttons.
//$button-selected-text: $button-active-text;
/// The border color of selected buttons.
//$button-selected-border: $button-active-bg;
/// The background gradient of selected buttons.
//$button-selected-gradient: null;
//$button-selected-shadow: null;
/// The shadow of focused buttons.
//$button-focused-shadow: null;

// Charts
// ===========================================================
/// The first base series color and its light and dark shades.
//$series-a:		get-base-hue( teal, 500 )	get-base-hue( teal, 500 )
/// The second base series color and its light and dark shades.
//$series-b:		get-base-hue( blue, 500 )	get-base-hue( blue, 500 )
/// The third base series color and its light and dark shades.
//$series-c:	get-base-hue( purple, 500 );
/// The fourth base series color and its light and dark shades.
//$series-d: get-base-hue( red, 500 );
/// The fifth base series color and its light and dark shades.
//$series-e: get-base-hue( yellow, 500 );
/// The sixth base series color and its light and dark shades.
//$series-f: get-base-hue( green, 500 );
/// The series colors in order: base, light, dark, lighter, darker
//$series-1: $series-a;
/// The color of the Chart grid lines (major).
//$chart-major-lines: rgba(0, 0, 0, .08);
/// The color of the Chart grid lines (minor).
//$chart-minor-lines: rgba(0, 0, 0, .04);

// Theme & Color System
// ===========================================================
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
$primary: $carbon-blue;
/// The color used along with the primary color denoted by $primary.
/// Used to provide contrast between the background and foreground colors.
//$primary-contrast: material-color( $primary-palette, default-contrast );
/// The secondary color of the theme.
$secondary: $carbon-orange;
/// The color used along with the secondary color denoted by $secondary.
/// Used to provide contrast between the background and foreground colors.
//$secondary-contrast: material-color( $secondary-palette, default-contrast );
/// The tertiary color of the theme.
//$tertiary: material-color($tertiary-palette, default);
/// The color used along with the tertiary color denoted by $tertiary.
/// Used to provide contrast between the background and foreground colors.
//$tertiary-contrast: material-color($tertiary-palette, default-contrast);
/// The color for informational messages and states.
//$info: #0058e9;
/// The color for success messages and states.
//$success: #37b400 !default;
/// The color for warning messages and states.
//$warning: #ffc000;
/// The color for error messages and states.
//$error: #f31700 !default;
/// The dark color of the theme.
//$dark: get-base-hue( grey, 800 );
/// The light color of the theme.
//$light: get-base-hue( grey, 100 );
/// Inverse color of the theme. Depending on the theme luminance dark or lught, it will be light or dark
//$inverse: if( $is-dark-theme, $light, $dark );

// Component
// ===========================================================
/// Background color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-bg` e.g. `$grid-bg: $component-bg !defualt;`.
//$component-bg: map-get($theme, component-bg);
/// Text color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-text` e.g. `$grid-text: component-text !default;`.
//$component-text: map-get($theme, component-text);
/// Border color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-border` e.g. `$grid-border: component-border !default;`.
//$component-border: map-get($theme, component-border);

// Grid
// ===========================================================
/// Background color of the grid component
//$grid-bg: $component-bg;
/// Text color of the grid component
//$grid-text: $component-text;
/// Border color of the grid component
//$grid-border: $component-border;
/// Background color of grid header
//$grid-header-bg: transparent;
/// Background color of grid header
//$grid-header-text: if($theme-type == dark, $light-secondary-text, $dark-secondary-text);
/// Border color of grid header
//$grid-header-border: $grid-border;
/// Background gradient of grid header
//$grid-header-gradient: null;
/// Background color of grid footer
//$grid-footer-bg: $grid-header-bg;
/// Text color of grid footer
//$grid-footer-text: $grid-header-text;
/// Border color of grid footer
//$grid-footer-border: $grid-header-border;
/// Background color of alternating rows in grid
//$grid-alt-bg: transparent;
/// Text color of alternating rows in grid
//$grid-alt-text: null;
/// Text color of alternating rows in grid
//$grid-alt-border: null;
/// Background color of hovered rows in grid
//$grid-hovered-bg: rgba( contrast-wcag( $grid-bg ), .07 );
/// Text color of hovered rows in grid
//$grid-hovered-text: $grid-text;
/// Border color of hovered rows in grid
//$grid-hovered-border: $grid-border;
/// Background color of selected rows in grid
//$grid-selected-bg: rgba( contrast-wcag( $grid-bg ), .04 );
/// Text color of selected rows in grid
//$grid-selected-text: #000000;
/// Border color of selected rows in grid
//$grid-selected-border: $grid-border;

// Icons
// ===========================================================
/// The URL to the icon font that will be used by the theme
/// The default value of `null` embeds the package font with a `data:` URL
//$icon-font-url: null;

// Shadows
// ===========================================================
/// Color of shadows
//$elevation: map-get( $theme, elevation );
/// Shadow for switch.
/// Equivalent to material elevation 1.
//$box-shadow-depth-1: 0 2px 1px -1px rgba( $elevation, .2 ), 0 1px 1px 0 rgba( $elevation, .14 ), 0 1px 3px 0 rgba( $elevation, .12 );
/// Shadow for resting state of button, card and other widgets.
/// Equivalent to material elevation 2.
//$box-shadow-depth-2: 0 3px 1px -2px rgba( $elevation, .2 ), 0 2px 2px 0 rgba( $elevation, .14 ), 0 1px 5px 0 rgba( $elevation, .12 );
/// Shadow for menu, popups and raised state of button and card.
/// Equivalent to material elevation 8.
//$box-shadow-depth-3: 0 5px 5px -3px rgba( $elevation, .2 ), 0 8px 10px 1px rgba( $elevation, .14 ), 0 3px 14px 2px rgba( $elevation, .12 );
/// Shadow for drawers and other overlaying elements.
/// Equivalent to material elevation 16.
//$box-shadow-depth-4: 0 8px 10px -5px rgba( $elevation, .2 ), 0 16px 24px 2px rgba( $elevation, .14 ), 0 6px 30px 5px rgba( $elevation, .12 );
/// Shadow for window and dialog.
/// Equivalent to material elevation 24.
//$box-shadow-depth-5: 0 11px 15px -7px rgba( $elevation, .2 ), 0 24px 38px 3px rgba( $elevation, .14 ), 0 9px 46px 8px rgba( $elevation, .12 );

// Tabstrip
// ===========================================================
/// Background color of tabstrip component
//$tabstrip-bg: null;
/// Text color of tabstrip component
//$tabstrip-text: $subtle-text;
/// Border color of tabstrip component
//$tabstrip-border: $component-border;
/// Horizontal padding of tabs
//$tabstrip-item-padding-x: 24px;
/// Vertical padding of tabs
//$tabstrip-item-padding-y: 14px;
/// Width of border around of tabs
//$tabstrip-item-border-width: 0px;
/// Border radius of tabs
//$tabstrip-item-border-radius: 0px;
/// Spacing between tabs
//$tabstrip-item-gap: 0px;
/// Background color of tabs
//$tabstrip-item-bg: null;
/// Text color of tabs
//$tabstrip-item-text: $subtle-text;
/// Border color of tabs
//$tabstrip-item-border: null;
/// Background gradient of tabs
//$tabstrip-item-gradient: null;
/// Background color of hovered tabs
//$tabstrip-item-hovered-bg: $hovered-bg;
/// Text color of hovered tabs
//$tabstrip-item-hovered-text: $body-text;
/// Border color of hovered tabs
//$tabstrip-item-hovered-border: null;
/// Background gradent of hovered tabs
//$tabstrip-item-hovered-gradient: null;
/// Background color of selected tabs
//$tabstrip-item-selected-bg: null;
/// Text color of selected tabs
//$tabstrip-item-selected-text: $body-text;
/// Border color of selected tabs
//$tabstrip-item-selected-border: null;
/// Background gradient of selected tabs
//$tabstrip-item-selected-gradient: null;
//$tabstrip-item-focused-shadow: $list-item-focused-shadow;
/// Horizontal padding of tabstrip content
//$tabstrip-content-padding-x: $tabstrip-item-padding-x;
/// Vertical padding of tabstrip content
//$tabstrip-content-padding-y: $tabstrip-item-padding-y;
/// Width of border around tabstrip content
//$tabstrip-content-border-width: 0px;
/// Background color of tabstrip content
//$tabstrip-content-bg: transparent;
/// Text color of tabstrip content
//$tabstrip-content-text: null;
/// Border color of tabstrip content
//$tabstrip-content-border: null;
/// Border color of tabstrip focused content
//$tabstrip-content-border-focused: $component-text;

// Toolbar
// ===========================================================
/// The horizontal padding of the container.
//$toolbar-padding-x: 6px;
/// The vertical padding of the container.
//$toolbar-padding-y: 6px;
