.row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  &.aligned-center {
    align-items: center;
  }

  &.aligned-baseline {
    align-items: baseline;
  }

  .radio &,
  .checkbox & {
    align-items: center !important;
  }
}

// Columns
.col,
.col-1,
.col-2,
.col-3 {
  display: flex;
  flex-flow: column wrap;
  flex-basis: 100%;

  &.justified-center {
    justify-content: center;
  }

  .column-spacing & {
    @media ($tablet) {
      // Add spacing between columns on tablet-wide or larger
      // by adding .column-spacing to the '.row' element
      padding: 0 1rem;
    }
  }

  // Columns only active on tablet-wide and above
  @media ($tablet-wide) {
    flex: 1;
  }

  // to enable column on mobile
  &.is-mobile {
    flex: 1;
  }
}

.col-2 {
  // Columns only active on tablet-wide and above
  @media ($tablet-wide) {
    flex: 2;
  }

  // to enable column on mobile
  &.is-mobile {
    flex: 2;
  }
}

.col-3 {
  // Columns only active on tablet-wide and above
  @media ($tablet-wide) {
    flex: 3;
  }

  // to enable column on mobile
  &.is-mobile {
    flex: 3;
  }
}

.hidden {
  display: none !important;
}

.hidden {
  &-mobile {
    @media ($under-tablet) {
      display: none !important;
    }
  }
  &-tablet {
    @media ($tablet) and ($under-tablet-wide) {
      display: none !important;
    }
  }
  &-tablet-wide {
    @media ($tablet-wide) and ($under-desktop) {
      display: none !important;
    }
  }
  &-desktop {
    @media ($desktop) {
      display: none !important;
    }
  }
}

.transparent {
  background: transparent !important;
}

.unselectable {
  @include unselectable;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-centered {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-emphasized {
  color: $black;
  font-weight: 600;
  line-height: 1.3;
  font-size: 1.8rem;
}

.sr,
.sr-only {
  // Text meant only for screen readers.
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.cf,
.clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

.error-text {
  color: $error;
}

.success-text {
  color: $success;
}
