button.k-button {
  color: #000000;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 4px 12px 4px 10px;
}

button.k-button.carbon-orange-btn {
  color: #ffffff;
  background: var(--carbon-orange);
  border: 1px solid var(--carbon-orange);
}

button.k-button.carbon-link-btn {
  color: var(--carbon-blue);
  background: transparent;
  border: none;
  box-sizing: initial;
  border-radius: initial;
  box-shadow: none;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 8px 4px 8px 2px;
  text-align: left;
}

ul.k-radio-list {
  li.k-radio-item {
    input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    label {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: var(--carbon-black);
    }
  }
}

button.k-button.carbon-remove-button {
  color: var(--carbon-red);
  border: 2px solid var(--carbon-red);
}

button.k-button.carbon-toolbar-item-btn {
  color: var(--carbon-black);
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: none;
  padding: 0;
  text-align: left;
  height: 24px;
  :hover {
    color: var(--carbon-white);
    background-color: var(--carbon-blue);
    border-radius: 6px;
  }

  &.selected {
    color: var(--carbon-white);
    background-color: var(--carbon-blue);
    border-radius: 6px;
  }
}

button.k-button.previewer-icon-button {
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: none;
  padding: 0;
  text-align: left;
  height: 24px;
}

button.k-button.k-state-selected {
  background-color: var(--carbon-orange);
}

.previewer-button-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.5) !important;
  color: var(--carbon-white) !important;
  border-radius: 14px !important;
  border: 0 !important;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.previewer-button-text-container {
  order: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  white-space: nowrap; /*Keep text always one line*/
  overflow: hidden;
  width: 0;
  transition: width 0.7s;
}

.previewer-button-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.previewer-button-icon-container:hover + .previewer-button-text-container {
  width: 60px;
}
