@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

// Carbon Colors
$carbon-black: #000000;
$carbon-lightorange: #fef4f0;
$carbon-orange: #ef5e25;
$carbon-darkOrange: #d2460f;
$carbon-lightBlue: #edf8fb;
$carbon-blue: #36a6de;
$carbon-darkBlue: #0094c6;
$carbon-veryLightGray: #fafafa;
$carbon-lighterGray: #eeeeee;
$carbon-lightGray: #cccccc;
$carbon-mediumGray: #979797;
$carbon-darkGray: #555555;
$carbon-veryDarkGray: #333333;
$carbon-white: #ffffff;
$carbon-gridDropShadow: rgba(0, 0, 0, 0.12);
$carbon-primaryButtonHover: #0094c612;
$carbon-green: #24a463;
$carbon-yellowgreen: #90b256;
$carbon-yellow: #fcbf49;
$carbon-lightred: #f2d0d6;
$carbon-red: #d91433;
$carbon-darkred: #520713;

// Colors
$brand: $carbon-orange;
$success: green;
$error: red;
$gray: #cccccc;
$black: #333333;
$white: #ffffff;
$border-color: rgba($gray, 0.15);
$maintenance-background: rgba(239, 94, 37, 0.6);

// Button colors
$button-colors: (
  "brand": (
    $white,
    $brand
  ),
  "white": (
    $black,
    $white
  ),
  "black": (
    $white,
    $black
  )
);

// Text colors
$text-colors: (
  "brand": $brand,
  "white": $white,
  "black": $black
);

// Breakpoints
$gap: 20px;

// Mobile-first, then work their way up
// Mobile Narrow
$under-mobile-narrow: "max-width: 418px";
$mobile-narrow: "min-width: 419px";
// Tablet
$under-tablet: "max-width: 768px";
$tablet: "min-width: 769px";
// Tablet-Wide
$under-tablet-wide-width: 960px + (2 * $gap) - 1px;
$under-tablet-wide: "max-width: #{$under-tablet-wide-width}";
$tablet-wide-width: 960px + (2 * $gap);
$tablet-wide: "min-width: #{$tablet-wide-width}";
// Desktop
$under-desktop-width: 1200px + (2 * $gap) - 1px;
$under-desktop: "max-width: #{$under-desktop-width}";
$desktop-width: 1200px + (2 * $gap);
$desktop: "min-width: #{$desktop-width}";

$content-max-width: 1220px + (2 * $gap);

// Fonts
$family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif;
$family-monospace: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;

// Styled fonts (add custom to beginning)
$body-font: "Source Sans Pro", $family-sans-serif;
$header-font: "Source Sans Pro", $family-sans-serif;
$code-font: $family-monospace;

// Element properties
$radius: 18px;
$radius-small: $radius; // alias
$radius-medium: 30px;
$radius-large: 60px;
$radius-rounded: 290486px; // Rounded border-radius
$box-shadow: 0px 0px 20px rgba(25, 36, 47, 0.1);

// we need to have a primary and secondary color pallete for our app theme
// instead of listing explicit colors only -Ali
