ul,
ol {
  padding: 0;
  margin: 0;

  li {
    margin-left: 0;
  }
}

.k-list-container .k-list li {
  border-left: 5px solid transparent;
  white-space: pre;
}

.k-list-container .k-list li.k-state-selected,
.k-list-container .k-list li.k-state-selected:hover,
.k-list-container .k-list li.k-state-selected:active {
  color: var(--carbon-black);
  background-color: var(--carbon-lightblue);
  border-left: 5px solid var(--carbon-blue);
}
