ul li.k-state-selected {
  background-color: var(--carbon-lightblue) !important;
  border-left: 5px solid var(--carbon-blue) !important ;
  color: black !important;
}

.k-list .k-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-left: 5px solid transparent;
  &:hover:not(.k-state-selected) {
    background-color: var(--carbon-verylightgray) !important;
    border-left: 5px solid var(--carbon-mediumgray) !important ;
    color: black !important;
  }
}
